
define('app/controller',['marionette'], function(Marionette) {
    
    var Controller = Marionette.Controller.extend({
        
        constructor: function() {
            this.actions = this.actions || {};
            this.views = {};
            Marionette.Controller.apply(this, arguments);
        },
        
        navigate: function(route,  options) {
            if (this.app) {
                if (this.prefix) route = _.compact([].concat(this.prefix).concat(route)).join('/');
                return this.app.navigate(route,  options);
            }
        },
        
        action: function(name, methodName) {
            if (_.isString(methodName)) this.actions[name] = methodName;
            return this.actions[name];
        },
        
        callAction: function(name) {
            var action = this.action(name);
            if (action && _.isFunction(this[action])) {
                this[action].apply(this, _.flatten(_.rest(arguments)));
                return action;
            }
        }
        
    });
    
    return Controller;
    
});