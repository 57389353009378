
define('app/config',['backbone', 'backbone.deep-model'], function(Backbone) {
    
    var Model = Backbone.DeepModel.extend({
        
        defaults: {
            locales: ['en']
        },
        
    });
    
    return Model;
    
});