
define('app/translation',['underscore', 'backbone'], function(_, Backbone) {
    
    var Model = Backbone.DeepModel.extend({
        
        initialize: function() {
            Backbone.DeepModel.prototype.initialize.apply(this, arguments);
            this.data = {}; // default interpolation data
        },
        
        // ### translate(key, options)
        //
        // The most-used method. Provide a key, and `translate` will return the
        // phrase.
        //
        //     translate("hello");
        //     => "Hello"
        //
        // Pass in an object as the second argument to perform interpolation.
        //
        //     translate("hello_name", {name: "Spike"});
        //     => "Hello, Spike"
        //
        // If you like, you can provide a default value in case the phrase is missing.
        // Use the special option key "_" to specify a default.
        //
        //     translate("i_like_to_write_in_language", {
        //       _: "I like to write in %{language}.",
        //       language: "JavaScript"
        //     });
        //     => "I like to write in JavaScript."
        //
        // Pluralizatione example:
        //
        // 'number_of_items': '%{_count} item |||| %{_count} items'
        
        translate: function(key, options) {
            if (_.isEmpty(this.attributes)) return;
            var result;
            options = options == null ? {} : options;
            // allow number as a pluralization shortcut
            if (typeof options === 'number') {
                options = { _count: options };
            }
            var phrase = this.get(key) || options._ || '';
            if (phrase === '') {
                console.log('Missing translation for key: "'+key+'"');
            } else {
                options = _.clone(options);
                result = choosePluralForm(phrase, this.locale, options._count);
                result = interpolate(result, options);
            }
            return result;
        },
        
        translateElement: function(element, key, options) {
            if (_.isEmpty(this.attributes)) return;
            if (_.isObject(key)) options = key, key = null;
            // Backbone.View, DOM elem or selector
            var elem = $(element instanceof Backbone.View ? element.el : element);
            if (_.isString(key)) { // key or attr:key
                var data = elem.data('i18n-args'); // json data
                data = _.extend({}, this.data, _.isObject(data) ? data : {}, options);
                _.each(key.split('|'), function(key) {
                    var delimIdx = key.indexOf(':');
                    if (delimIdx > -1) { // attr:key
                        var attr = key.slice(0, delimIdx);
                        key = key.slice(delimIdx + 1);
                        elem.attr(attr, this.translate(key, data));
                    } else {
                        elem.html(this.translate(key, data));
                    }
                }.bind(this));
            } else {
                if (elem.is('[data-i18n]')) {
                    this.translateElement(elem, elem.data('i18n'), options);
                }
                elem.find('[data-i18n]').each(function(idx, elem) {
                    this.translateElement(elem, $(elem).data('i18n'), options);
                }.bind(this));
            }
        }
        
    });
    
    // Based on Polyglot by AirBnB
    
    // #### Pluralization methods
    // The string that separates the different phrase possibilities.
    var delimeter = '||||';

    // Mapping from pluralization group plural logic.
    var pluralTypes = {
        chinese:   function(n) { return 0; },
        german:    function(n) { return n !== 1 ? 1 : 0; },
        french:    function(n) { return n > 1 ? 1 : 0; },
        russian:   function(n) { return n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2; },
        czech:     function(n) { return (n === 1) ? 0 : (n >= 2 && n <= 4) ? 1 : 2; },
        polish:    function(n) { return (n === 1 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2); },
        icelandic: function(n) { return (n % 10 !== 1 || n % 100 === 11) ? 1 : 0; }
    };

    // Mapping from pluralization group to individual locales.
    var pluralTypeToLanguages = {
        chinese:   ['id', 'ja', 'ko', 'ms', 'th', 'tr', 'zh'],
        german:    ['da', 'de', 'en', 'es', 'fi', 'el', 'he', 'hu', 'it', 'nl', 'no', 'pt', 'sv'],
        french:    ['fr', 'tl'],
        russian:   ['hr', 'ru'],
        czech:     ['cs'],
        polish:    ['pl'],
        icelandic: ['is']
    };

    function langToTypeMap(mapping) {
        var type, langs, l, ret = {};
        for (type in mapping) {
            if (mapping.hasOwnProperty(type)) {
                langs = mapping[type];
                for (l in langs) {
                    ret[langs[l]] = type;
                }
            }
        }
        return ret;
    }

    // Trim a string.
    function trim(str){
        var trimRe = /^\s+|\s+$/g;
        return str.replace(trimRe, '');
    }

    // Based on a phrase text that contains `n` plural forms separated
    // by `delimeter`, a `locale`, and a `count`, choose the correct
    // plural form, or none if `count` is `null`.
    function choosePluralForm(text, locale, count){
        var ret, texts, chosenText;
        if (count != null && text) {
            texts = text.split(delimeter);
            chosenText = texts[pluralTypeIndex(locale, count)] || texts[0];
            ret = trim(chosenText);
        } else {
            ret = text;
        }
        return ret;
    }

    function pluralTypeName(locale) {
        var langToPluralType = langToTypeMap(pluralTypeToLanguages);
        return langToPluralType[locale] || langToPluralType.en;
    }

    function pluralTypeIndex(locale, count) {
        return pluralTypes[pluralTypeName(locale)](count);
    }

    // ### interpolate
    //
    // Does the dirty work. Creates a `RegExp` object for each
    // interpolation placeholder.
    function interpolate(phrase, options) {
        options = smash(options);
        for (var arg in options) {
            if (arg !== '_' && options.hasOwnProperty(arg)) {
                // We create a new `RegExp` each time instead of using a more-efficient
                // string replace so that the same argument can be replaced multiple times
                // in the same phrase.
                phrase = phrase.replace(new RegExp('%\\{'+arg+'\\}', 'g'), options[arg]);
            }
        }
        return phrase;
    }
    
    function smash(obj, seperator) {
        var ret = {};
        _.forIn(obj, function(val, key) {
            if (_.isObject(val)) {
                //Recursion for embedded objects
                var obj2 = smash(val);
                _.forIn(obj2, function(val2, key2) {
                    ret[key+'.'+key2] = val2;
                });
            } else {
              ret[key] = val;
            }
        });
        return ret;
    };
    
    return Model;
    
});
