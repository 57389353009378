
define('app',['marionette', 'app/application', 'nprogress'], function(Marionette, App, NProgress) {
    
    // Configuration
    
    App.config('idleTimeout', 5000);
        
    App.locales('en', 'nl');
    
    App.on('error', function() {
        App.navigate('', { trigger: true });
    });
    
    // Ajax Events
    
    App.on('load:start', function() {
        NProgress.start();
        $('body').addClass('loading');
    });
    
    App.on('load:stop', function() {
        setTimeout(function() {
            NProgress.done();
            $('body').removeClass('loading');
        }, 500);
    });
    
    // Regions
    
    App.addRegions({
        mainRegion: '#main-region',
        navRegion: '#nav-region'
    });
    
    // Initializers
    
    App.trigger('load:start');
        
    App.on('start', function(options) {
        App.trigger('load:stop');
        App.slices.get('main').then(function() {
            App.init(options);
        });
    });
    
    return App;
    
});
