
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define('marionette.overlay',['jquery', 'underscore', 'marionette'], factory);
    } else {
        factory(jQuery, _, Marionette);
    }
}(function($, _, Marionette) {
    
    $.event.special['transition:end'] = $.event.special['transition:end'] || {
        _events: 'transitionend webkitTransitionEnd oTransitionEnd otransitionend',
        setup: function(data, namespaces) {
            var self = this;
            $(this).on($.event.special['transition:end']._events, function(event) {
                $.event.special['transition:end'].handler.call(self, event);
            });
        },
        teardown: function(namespaces){
            $(this).off($.event.special['transition:end']._events);
        },
        handler: function(event) {
            event.type = 'transition:end';
            $.event.dispatch.call(this, event);
        }
    };
    
    var Overlay = Marionette.ItemView.extend({
        
        template: _.template('<button type="button" class="overlay-close">Close</button><%= obj.content %>'),
        
        events: {
            'click .overlay-close': 'hide',
            'click a': 'onClick'
        },
        
        className: 'overlay',
        
        effect: 'push-left',
        
        container: 'body > .container',
        
        constructor: function(options) {
            options = _.extend({}, options);
            this.options = _.pick(options, 'effect', 'container', 'observeLinks');
            this.config = _.extend({}, this.constructor.config, options.config);
            Marionette.View.prototype.constructor.apply(this, arguments);
            var childView = Marionette.getOption(this, 'childView');
            if (childView) this.setView(new childView());
            if (options.name) this.name(options.name);
        },
        
        name: function(name) {
            if (arguments.length > 0) {
                this.$el.attr('data-name', name);
            }
            return this.$el.attr('data-name');
        },
        
        isVisible: function() {
            return this.$el.hasClass('open');
        },
        
        toggle: function(event) {
            if (event) event.preventDefault();
            if (this.isVisible()) {
                this.hide();
            } else {
                this.show();
            }
        },
        
        show: function(event) {
            if (event) event.preventDefault();
            if (this.$el.hasClass('open')) return;
            this.updateLayout(true);
            this.triggerMethod('before:show');
            if (this.$container) this.$container.trigger('overlay:before:show', this.el);
            this.$el.addClass('open');
            if (this.$container) this.$container.addClass('overlay-open');
            this.transitionEnd(function() {
                this.$el.addClass('active');
                this.triggerMethod('show');
                if (this.view instanceof Backbone.View) this.view.render();
                if (this.$container) this.$container.trigger('overlay:show', this.el);
            }, Marionette.getOption('watchForProperty'));
        },
        
        hide: function(event) {
            if (event) event.preventDefault();
            if (!this.$el.hasClass('open')) return;
            this.triggerMethod('before:hide');
            if (this.$container) this.$container.trigger('overlay:before:hide', this.el);
            this.$el.removeClass('open');
            if (this.$container) this.$container.removeClass('overlay-open');
            this.transitionEnd(function() {
                this.$el.removeClass('active');
                this.triggerMethod('hide');
                if (this.$container) this.$container.trigger('overlay:hide', this.el);
            }, Marionette.getOption('watchForProperty'));
        },
        
        reset: function() {
            delete this.view;
            this.setContent();
        },
        
        transitionEnd: function(callback, watchForProperty) {
            var transitions = Modernizr && Modernizr.csstransitions;
            var onEndTransitionFn = function(ev) {
                var originalEvent = ev.originalEvent || ev;
                if (transitions && watchForProperty // not ready yet
                    && originalEvent.propertyName !== watchForProperty) return;
                callback.call(this, originalEvent);
            }.bind(this);
            if (transitions) {
                this.$el.one('transition:end', onEndTransitionFn);
            } else {
                onEndTransitionFn();
            }
        },
        
        setView: function(view) {
            if (view instanceof Backbone.View) {
                this.view = view;
            } else if (this.view && _.isFunction(this.view.destroy)) {
                this.view.destroy();
                delete this.view;
            }
        },
        
        setContent: function(content, skipRender) {
            if (this.view instanceof Backbone.View 
                && _.isFunction(this.view.setContent)) {
                this.view.setContent.apply(this.view, arguments);
            } else {
                if (content instanceof jQuery) {
                    this.content = content.html();
                } else {
                    this.content = content;
                }
                if (this.model instanceof Backbone.Model) {
                    this.model.set('content', this.content);
                }
                this.setView();
            }
            if (!skipRender) return this.render();
        },
        
        serializeData: function() {
            if (this.model instanceof Backbone.Model) {
                return Marionette.ItemView.prototype.serializeData.apply(this, arguments);
            } else if (_.isObject(this.data)) {
                return this.data;
            } else if (this.content) {
                return { content: this.content }
            } else {
                return {};
            }
        },
                
        render: function() {
            if (Marionette.getOption(this, 'template')) {
                Marionette.ItemView.prototype.render.apply(this, arguments);
            } else if (_.isString(this.content)) {
                this.$el.html(this.content);
                this.delegateEvents();
            }
            
            if (this.view instanceof Backbone.View) {
                if (!$.contains(this.el, this.view.el)) {
                    this.$el.append(this.view.el);
                    this.view.render();
                    this.triggerMethod('render:view', this.view);
                }
            }
                                   
            var effect = Marionette.getOption(this, 'effect');
            var effectClass = 'overlay-' + effect;
            if (!this.$el.hasClass(effectClass)) { // initial setup
                this.$el.addClass(effectClass);
                var container = Marionette.getOption(this, 'container');
                if (_.isString(container)) {
                    this.$container = $(container).eq(0);
                    this.$container.addClass('container-' + effect);
                } else if (container instanceof jQuery) {
                    this.$container = container.eq(0);
                }
                if (Marionette.getOption(this, 'singleton')) {
                    $(document).bind('overlay:show.singleton', function(event, overlay) {
                        if (overlay.id && overlay.id !== this.id) {
                            this.enforceSingleton();
                        }
                    }.bind(this));
                }
            }
            
            this.bindUIElements();
            this.bindToggleElements();
            this.updateLayout(true);
        },
        
        updateLayout: function(force) {
            if (this.$el.is(':visible') || force) {
                this.$el.find('.center').each(function() {
                    var $el = $(this);
                    $el.css({ position: 'absolute', top: '50%', left: '50%',
                        'margin-top': '-' + ($el.outerHeight() / 2) + 'px',
                        'margin-left': '-' + ($el.outerWidth() / 2) + 'px'
                    });
                });
                if (this.view instanceof Backbone.View &&
                    _.isFunction(this.view.updateLayout)) {
                    this.view.updateLayout();
                }
            }
        },
        
        enforceSingleton: function() {
            _.defer(this.hide.bind(this));
        },
        
        bindToggleElements: function(unbind) {
            var autobind = Marionette.getOption(this, 'autobind');
            if (autobind) {
                var selector = '[data-target="' + (this.id || 'overlay') + '"]';
                var elements = $(selector, _.isBoolean(autobind) ? document : autobind);
                elements.unbind('click.overlay');
                if (unbind) return;
                elements.bind('click.overlay', this.toggle.bind(this));
            }
        },
        
        onClick: function(event) { // wait until overlay has been closed
            if (this.options.observeLinks && this.$el.hasClass('open')) { 
                event.preventDefault();
                var target = $(event.target).closest('a');
                var href = target.attr('href');
                if (href) { 
                    this.hide();
                    var callback = this.triggerMethod.bind(this, 'navigate:anchor', href, target.data());
                    this.once('hide', callback);
                }
            }
        },
        
        onDestroy: function() {
            this.bindToggleElements(true);
            if (this.$container) this.$container.unbind('overlay:show.singleton');
            if (this.view && _.isFunction(this.view.onDestroy)) this.view.onDestroy(); 
        }
        
    });
    
    return Marionette.Overlay = Overlay;
    
}));