
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define('marionette.scrollview',['jquery', 'underscore', 'marionette', 'iscroll'], factory);
    } else {
        factory(jQuery, _, Marionette);
    }
}(function($, _, Marionette) {
    
    var Config = {
        mouseWheel: true,
        wheelEvent: true,
        scrollbars: true,
        fadeScrollbars: true,
        interactiveScrollbars: true,
        keyBindings: true,
        scrollX: false,
        probeType: 2,
        tap: true
    };
    
    var Methods = {
        
        refresh: function() {
            this.updateLayout();
        },
        
        updateLayout: function() {
            if (this.scroller) this.scroller.refresh();
        },
        
        prev: function() {
            if (this.scroller) this.scroller.prev.apply(this.scroller, arguments);
        },
        
        next: function() {
            if (this.scroller) this.scroller.next.apply(this.scroller, arguments);
        },
        
        goToPage: function(x, y, time, easing) {
            if (this.scroller) this.scroller.goToPage.apply(this.scroller, arguments);
        },
        
        scrollTo: function(x, y, time, easing) {
            if (this.scroller) this.scroller.scrollTo.apply(this.scroller, arguments);
        },
        
        scrollBy: function(x, y, time, easing) {
            if (this.scroller) this.scroller.scrollBy.apply(this.scroller, arguments);
        },
        
        scrollToElement: function(el, time, offsetX, offsetY, easing) {
            if (this.scroller) this.scroller.scrollToElement.apply(this.scroller, arguments);
        },
        
        cancelScroll: function() {
            if (this.scroller) this.scroller.initiated = false;
        },
        
        isTopActive: function(strict) {
            var scrollable = this.isScrollable() && this.scroller.options.scrollY;
            if (strict && scrollable) {
                return this.scroller.y === 0;
            } else if (this.topTreshold && scrollable) {
                return this.scroller.y + this.topTreshold >= 0;
            }
            return false;
        },
        
        isBottomActive: function(strict) {
            var scrollable = this.isScrollable() && this.scroller.options.scrollY;
            if (strict && scrollable) {
                return this.scroller.y === this.scroller.maxScrollY;
            } else if (this.bottomTreshold && scrollable) {
                return this.scroller.y < this.scroller.maxScrollY + this.bottomTreshold;
            }
            return false;
        },
        
        isScrolling: function() {
            return this.$el.hasClass('scrolling');
        },
        
        ensureScroller: function() {
            _.defer(function() {
                if (this.scroller) return this.scroller.refresh();
                
                var defaults = {};
                var scrollOptions = Marionette.getOption(this, 'scroll');
                var options = _.extend(defaults, this.constructor.config, scrollOptions);
                
                this.scroller = new IScroll(this.el, options);
                this.triggerMethod('init:scroller', this.scroller);
                this.$el.data('iscroll', this.scroller);
                
                this.scroller.on('beforeScrollStart', this._beforeScroll.bind(this));
                this.scroller.on('scrollEnd', this._afterScroll.bind(this));
                this.scroller.on('scrollCancel', this._afterScroll.bind(this));
                
                this.scroller.on('beforeScrollStart', this.triggerMethod.bind(this, 'before:scroll:start'));
                this.scroller.on('scrollStart', this.triggerMethod.bind(this, 'scroll:start'));
                this.scroller.on('scroll', this.triggerMethod.bind(this, 'scroll:move'));
                this.scroller.on('scrollEnd', this.triggerMethod.bind(this, 'scroll:end'));
                this.scroller.on('scrollCancel', this.triggerMethod.bind(this, 'scroll:cancel'));
                this.scroller.on('wheel', this.triggerMethod.bind(this, 'scroll:wheel'));
                
                if (options.flick) {
                    this.scroller.on('flick', this.triggerMethod.bind(this, 'scroll:flick'));
                }
                if (options.tap) {
                    this.scroller.on('tap', this.triggerMethod.bind(this, 'tap'));
                }
                if (options.zoom) {
                    this.scroller.on('zoomStart', this.triggerMethod.bind(this, 'zoom:start'));
                    this.scroller.on('zoomEnd', this.triggerMethod.bind(this, 'zoom:end'));
                }
            }.bind(this));
        },
        
        _beforeScroll: function() {
            this.$el.addClass('scrolling');
        },
        
        _afterScroll: function() {
            this.$el.removeClass('scrolling');
        }
        
    };
    
    var ScrollView = Marionette.ItemView.extend({
        
        className: 'scrollable',
        
        constructor: function(options) {
            Marionette.ItemView.prototype.constructor.apply(this, arguments);
            ScrollView.mixin(this);
        },
        
        setContent: function(content) {
            if (content instanceof jQuery) {
                this.renderHTML(content.html());
            } else if (content) {
                this.renderHTML(content);
            }
        },
        
        render: function() {
            this.$el.addClass(this.className);
            var template = this.getTemplate();
            if (template) {
                var data = this.serializeData();
                data = this.mixinTemplateHelpers(data);
                var html = Marionette.Renderer.render(template, data);
                this._render(this.renderHTML, html);
            } else {
                this._render(this.renderHTML);
            }
            return this;
        },
        
        renderHTML: function(html) {
            if (!this.$wrapper && this.$el.children('> .pane').length === 1) {
                this.$wrapper = this.$el.children('> .pane').first();
            } else {
                this.$wrapper = this.$el.wrapInner('<div class="pane">').children(':first');
            }
            
            if (html instanceof jQuery) {
                this.$wrapper.html(content.html());
            } else if (html instanceof HTMLElement) {
                this.$wrapper.empty();
                this.$wrapper.append(html);
            } else if (_.isString(html)) {
                this.$wrapper.html(html);
            }
            
            this.ensureScroller();
        },
        
        _render: function(callback, html) {
            this.isDestroyed = false;
            
            if (this.spawn) this.spawn('before:render');
            this.triggerMethod('before:render', this);
            this.triggerMethod('before:render', this);
            
            if (callback) callback.call(this, html);
            
            this.bindUIElements();
            
            this.triggerMethod('render', this);
            this.triggerMethod('render', this);
            if (this.spawn) this.spawn('render');
        }
        
    });
    
    ScrollView.mixin = function(view) {
        _.extend(view, Methods);
        _.extend(view.constructor, { config: _.clone(Config) });
        view.on('before:destroy', function() {
            if (this.$el.data('iscroll')) {
                if (this.scroller) this.scroller.destroy();
                this.scroller = null;
            }
        }.bind(view));
    };
    
    return Marionette.ScrollView = ScrollView;
    
}));