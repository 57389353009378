
define('app/router',['marionette', 'app/env', 'backbone.routefilter'], function(Marionette, env) {
    
    function parseQueryString(path) {
        var index = (path = path || '').indexOf('?');
        if (index == -1) return { _path: path };
        var qs = path.slice(index + 1);
        var pairs = qs.split('&');
        var result = {};
        pairs.forEach(function(pair) {
            pair = pair.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        });
        try {
            var json = JSON.parse(JSON.stringify(result));
            json._path = path.slice(0, index);
            return json;
        } catch(e) { 
            return { _path: path };
        }
    };
    
    var Router = Marionette.AppRouter.extend({
        
        prefix: ':locale',
        
        route: function(route, name, callback) { // router prefix handling
            var prefix = this.options.prefix || (this.options.controller && this.options.controller.prefix);
            if (_.isString(route) && route.indexOf('/') === 0) {
                route = route.slice(1); // root-url, non-prefixed
            } else if (_.isString(route) && (this.prefix || prefix)) {
                // combines router prefix with options.prefix
                var original = route;
                var segments = _.compact([].concat(this.prefix).concat(prefix));
                if (original.indexOf('*') === 0) { // handle splat routes
                    route = segments.join('/');
                    Marionette.AppRouter.prototype.route.call(this, route, name, callback);
                }
                route = segments.concat(original).join('/');
            }
            Marionette.AppRouter.prototype.route.call(this, route, name, callback);
        },
                
        before: {
            '*locale': 'detectLocale',
            '*before': 'beforeRoute'
        },
        
        parseQueryString: function(fragment, args, next) {
            var parsed = parseQueryString(_.last(args));
            if (args.length) args[args.length - 1] = parsed._path;
            Backbone.history.queryParams = parsed;
            delete parsed._path;
            next();
        },
        
        detectLocale: function(fragment, args, next) {
            if (args[0] && args[0].length === 2) {
                this.app.switchLocale(args.shift(), next);
            } else {
                next();
            }
        },
        
        beforeRoute: function(fragment, args, next) {
            next();
        }
    
    });
    
    return Router;
    
});