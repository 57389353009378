
define('app/model',['backbone'], function(Backbone) {
    
    var Model = Backbone.DeepModel.extend({
        
        // constructor: function() {
        //     Backbone.Model.apply(this, arguments);
        // }
        
    });
    
    return Model;
    
});