
define('app/application',['marionette', 'app/env', 'app/config', 'app/translation', 'app/router', 'app/controller', 'app/model', 'app/collection', 'lazyloader', 
    'marionette.overlay', 'marionette.scrollview', 'jquery.idle-timer', 'backbone.async.events'], 
    function(Marionette, Env, Config, Translation, Router, Controller, Model, Collection, LazyLoader) {
        
    var ScrollableOverlay = Marionette.Overlay.extend({
        childView: Marionette.ScrollView
    });
    
    var config = new Config(); // private singleton
    var translation = new Translation(); // private singleton
    var listeners = new Backbone.AsyncEvents(); // private singleton
    
    _.extend(Marionette.Region.prototype, {
        
        // Because open will destroy $el, all $el.data() will be cleared
        // causing major issues with any views storing data there.
        // Notably, all Backbone.Courier based views.
        
        detachView: function(view) {
            if (view === this.currentView) {
                this.currentView.$el.detach();
                return this.currentView;
            }
        },
        
        // Similar to 'show' but enables switching between multiple views
        // without destroying them.
        // Handles calling the `render` method for you. Reads content
        // directly from the `el` attribute. Also calls an optional
        // `onSwap` on the region, `onSwappedIn` on the new view, and
        // `onSwappedOut` on the old view, just after rendering the view.
        // Returns the old view (if defined).
        
        swap: function(view) {
            if (view === this.currentView) return view;
            this._ensureElement();
            
            var oldView = this.detachView(this.currentView);
            
            view.render();
            this.open(view);
            this.currentView = view;
            this.currentView.delegateEvents();
            
            Marionette.triggerMethod.call(this, 'swap', view);
            if (oldView) Marionette.triggerMethod.call(oldView, 'swapped:out', view);
            Marionette.triggerMethod.call(view, 'swapped:in', oldView);
            
            return oldView;
        }
    
    });
    
    var Application = Marionette.Application.extend({
                
        setup: function() {
            this.info = {};
            this.slices = new LazyLoader('slices', 'slice');
            this.views = new LazyLoader('views');
            this.templates = new LazyLoader('templates');
            this.translations = new LazyLoader('i18n!nls');
            var handler = this.triggerEvent.bind(this, 'change:translation', translation);
            this.listenTo(translation, 'change', handler);
        },
        
        init: function(options, callback) { // optional callback
            if (_.isFunction(options)) callback = options, options = {};
            options = _.extend({ pushState: false, root: '/' }, options, { silent: true });
            Backbone.history.start(options); // always start without triggering loadUrl
            
            // Set document flags
            $('html').removeClass('no-app').addClass('app');
            
            // Default title
            this.title = document.title;
            
            // Ajax events
            $(document).on('ajaxStart', this.triggerEvent.bind(this, 'ajax:start'));
            $(document).on('ajaxError', this.triggerEvent.bind(this, 'ajax:error'));
            $(document).on('ajaxProgress', this.triggerEvent.bind(this, 'ajax:progress'));
            $(document).on('ajaxStop', this.triggerEvent.bind(this, 'ajax:stop'));
            
            // Window Resize
            $(this.container || window).bind('resize.app', this.triggerEvent.bind(this, 'resize'));
            
            // Idle management
            var idleTimeout = this.config('idleTimeout') || 0;
            if (idleTimeout > 0) {
                $(document).idleTimer(idleTimeout, { moveTreshold: 10 });
                $(document).on('idle.idleTimer', this.triggerEvent.bind(this, 'idle'));
                $(document).on('active.idleTimer', this.triggerEvent.bind(this, 'active'));
            }
            
            // Application overlay
            this.overlay = new ScrollableOverlay({
                singleton: true, effect: 'push-top',
                id: 'overlay',
                className: 'overlay'
            });
            
            this.overlay.render();
            this.overlay.$el.appendTo('body');
            this.overlay.listenTo(this, 'resize', this.overlay.updateLayout);
            
            this.overlay.on('show', this.triggerEvent.bind(this, 'overlay:show'));
            this.overlay.on('hide', this.triggerEvent.bind(this, 'overlay:hide'));
            this.overlay.on('render:view', this.triggerEvent.bind(this, 'overlay:render:view'));
            
            this.detectLocale(true); // from url or browser
            
            this.on('navigate', function(route, options) {
                if (window.location.hostname === 'localhost' ||
                    window.location.hostname === '0.0.0.0') return;
                if (window.ga) {
                    var locale = ((options && options.locale) || this.locale());
                    var url = '/' + locale + (route ? '/' + route : '');
                    window.ga('send', 'pageview', url);
                }
            });
            
            this.triggerEvent('init');
            if (_.isFunction(callback)) {
                callback(this);
            } else {
                this.load();
            }
        },
        
        addListener: function(event, listener) {
            listeners.add(event, listener);
        },
        
        load: function() {
            if (!this.currentRoute(true)) {
                this.triggerEvent('load');
            } else {
                Backbone.history.loadUrl();
            }
        },
        
        reload: function() {
            this.triggerEvent('reload');
        },
        
        setTitle: function(title) {
            document.title = _.compact([this.title, title]).join(' / ');
            this.triggerEvent('change:title', document.title, title);
        },
        
        loadTranslation: function(locale, callback) {
            this.translations.get(locale + '/main').then(function(i18n) {
                translation.locale = locale;
                translation.set(i18n);
                if (_.isFunction(callback)) callback(i18n);
            }.bind(this));
        },
        
        translate: function(key, options) {
            return translation.translate(key, options) || this.translationFallback(key, options);
        },
        
        translationFallback: function(key, options) {
            return (options && options._) || 'TRANSLATION NOT FOUND';
        },
        
        translateElement: function(element, key, options) {
            translation.translateElement(element, key, options);
        },
        
        onChangeTranslation: function() {
            this.title = this.translate('site.title', { _: this.title });
            listeners.emit('change:translation', translation);
        },
        
        triggerEvent: function(name) {
            var args = [name].concat(this).concat(_.rest(arguments));
            this.triggerMethod.apply(this, arguments);
            this.vent.trigger.apply(this.vent, args);
        },
        
        config: function(key, value) {
            if (arguments.length === 2) {
                return config.set(key, value);
            } else if (_.isObject(key)) {
                return config.set(key);
            } else if (arguments.length === 1) {
                return config.get(key);
            } else {
                return config.attributes;
            }
        },
        
        observeConfig: function(attr, callback) {
            this.listenTo(config, 'change:' + attr, function(model, value) {
                var previous = model.previous(attr);
                if (_.isString(callback)) {
                    this.triggerEvent(callback, value, previous);
                } else if (_.isFunction(callback)) {
                    callback(value, previous);
                }
            });
        },
        
        route: function(route, options) {
            this.triggerEvent('route', route, options);
        },
        
        navigate: function(route,  options) {
            options = options || {};
            var normalizedRoute = options.route = route;
            if (options.locale !== false) {
                var locale = _.isString(options.locale) ? options.locale : this.locale();
                route = normalizedRoute = this.normalizeRoute(route);
                route = _.compact([locale].concat(route)).join('/');
                options.route = route; // localized route
            }
            if (normalizedRoute !== this.lastRoute) {
                Backbone.history.navigate(route, options);
                this.triggerEvent('navigate', normalizedRoute, options);
                this.lastRoute = normalizedRoute;
            }
        },
        
        currentRoute: function(strip) {
            var route = Backbone.history.fragment || '';
            return strip ? this.normalizeRoute(route) : route;
        },
        
        currentParams: function() {
            return _.extend({}, Backbone.history.queryParams);
        },
        
        locales: function(locales) {
            var availableLocales = this.config('locales');
            if (arguments.length > 0 || !availableLocales) {
                availableLocales = _.compact(_.flatten(arguments));
                this.config('locales', availableLocales);
            }
            return availableLocales;
        },
        
        locale: function(locale) {
            if (locale === true) {
                return this.config('locale') || this.detectLocale();
            } else if (arguments.length > 0) {
                var availableLocales = this.locales();
                if (_.isString(locale) && _.contains(availableLocales, locale)) {
                    this.config('locale', locale);
                } else {
                    this.detectLocale(true);
                }
            }
            return this.config('locale');
        },
        
        detectLocale: function(set) {
            if (set && this.config('locale')) return this.config('locale');
            var defaultLocale = this.config('defaults.locale');
            var availableLocales = this.locales();
            if (!defaultLocale) {
                defaultLocale = this.localeFragment();
            }
            if (!defaultLocale) {
                var lang = window.navigator.userLanguage || window.navigator.language || '';
                defaultLocale = lang.split(/_-/)[0].toLowerCase();
            }
            if (!_.contains(availableLocales, defaultLocale)) {
                defaultLocale = _.first(availableLocales);
            }
            this.config('defaults.locale', defaultLocale);
            if (set) this.config('locale', defaultLocale);
            return defaultLocale;
        },
        
        switchLocale: function(requested, callback) {
            var route = this.currentRoute(true);
            var previous = this.locale();
            var locale = this.locale(requested);
            if (locale != requested) { // missing locale, redirect
                this.navigate(route, { trigger: true, locale: locale });
            } else {
                this.loadTranslation(locale, function(i18n) {
                    listeners.emit('switch:locale', locale, previous, function() {
                        if (_.isFunction(callback)) callback();
                    });
                }.bind(this));
            }
        },
        
        normalizeRoute: function(route) {
            return this.localeFragment(route, true);
        },
        
        localeFragment: function(route, strip) {
            route = arguments.length > 0 ? route : this.currentRoute();
            var segments = _.segments(route);
            if (segments[0] && segments[0].length === 2) {
                return strip ? segments.slice(1).join('/') : segments[0];
            } else if (strip) {
                return route; // unchanged
            }
        },
        
        onIdle: function() {
            $('body').addClass('idle');
        },
        
        onActive: function() {
            $('body').removeClass('idle');
        },
        
        showOverlay: function(name, content, navigate) {
            if (arguments.length > 1) {
                this.overlay.name(name);
                content ? this.overlay.setContent(content) : this.overlay.reset();
            }
            if (arguments.length === 3) {
                this.overlay.once('show', this.navigate.bind(this, name));
                this.overlay.once('hide', this.navigate.bind(this, navigate));
            }
            _.defer(this.overlay.show.bind(this.overlay));
        },
        
        hideOverlay: function() {
            this.overlay.hide();
        },
        
        onOverlayShow: function() {
            this.onIdle();
        },
        
        onOverlayHide: function() {
            this.onActive();
        }
        
    });
    
    var App = new Application();
    
    App.addInitializer(App.setup.bind(App));
    
    // Application properties
    
    App.translation = translation;
    
    // Runtime environment, central event aggregator + config
    
    Env.vent = App.vent;
    Env.config = App.config.bind(App);
    Env.translate = App.translate.bind(App);
    
    // Base Classes
    
    App.Router = Router.extend({ app: App });
    App.Controller = Controller.extend({ app: App });
    App.Model = Model;
    App.Collection = Collection;
        
    // Observe configuration
        
    App.observeConfig('locale', 'change:locale');
    
    return App;
    
});
