
requirejs.config({
    baseUrl: '/js'
});

var mobile = document.cookie && document.cookie.match('mobile=1');
var isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);

if (document.location.pathname.indexOf('/mobile') === 0) {
    var enabled = mobile ? '0' : '1';
    document.cookie = 'mobile=' + enabled + '; path=/';
    document.location.href = '/';
} else if (mobile) {
    $('html').removeClass('js').addClass('no-js');
} else if (document.location.pathname !== '/' && !isBot) {
    document.location.href = '/#' + document.location.pathname.slice(1);
} else {
    require(['jquery', 'underscore', 'marionette', 'app', 'jquery.plugins'], function ($, _, Marionette, Application) {
        _.each(['aqua', 'blue', 'gold', 'green', 'orange', 'purple'], function(img) {
            new Image().src = '/img/gradient-' + img + '.jpg';
        });
        
        $('#intro fill').imagesLoaded().always( function(instance) {
            Application.start({ pushState: true });
        });
    });
}
;
define("main", function(){});
